import React, { useEffect, useState } from 'react'
function Main () {
  const [scrollPage, setScrollPage] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
      setScrollPage(window.scrollY / window.innerHeight);
      // console.log(window.scrollY, Math.floor(window.scrollY / window.innerHeight));
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="app">
      <nav className="header">
        <a className="header-logo-link" href="/">
          <img className="header-logo" src="nightmare-party-logo-drawn-dark-bg-sm.png" alt="Nightmare Party logo" />
        </a>
        {/*<div className="center-container">*/}
        {/*  <div className="header-nav">*/}
        {/*    <a href="https://presskit.nightmarepartygame.com" target="_blank">Presskit</a>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </nav>

      <div className="hero">
        <div className="center-container no-max-width">
          <div className="content">
            <h1 className="text-center mb-none">
              <span className="small">
                <span className="blink-in o-0" style={{animationDelay: '.5s', animationDuration: '4s', animationFillMode: 'forwards', animationIterationCount: 'infinite'}}>WELCOME </span>
                <span className="blink-in o-0" style={{animationDelay: '.3s', animationDuration: '5s', animationFillMode: 'forwards', animationIterationCount: 'infinite'}}>TO </span>
                <span className="blink-in o-0" style={{animationDelay: '.4s', animationDuration: '6s', animationFillMode: 'forwards', animationIterationCount: 'infinite'}}>YOUR</span>
              </span>
              <span className="big stamp o-0" style={{animationDelay: '1s', animationDuration: '.1s', animationFillMode: 'forwards'}}>NIGHTMARE</span>
            </h1>
            <h2 className="text-center mb-none" style={{ marginTop: '24px' }}>
              <span className="dark-bg med">
                COMING 2024
              </span>
            </h2>
            <div className="text-center" style={{ color: '#fff' }}>
              <span>Horror multiplayer co-op survival FPS</span>
              <br />
              <span style={{ fontSize: '.8em', color: 'rgba(255, 255, 255, .7)' }}>
                <i className="fa fa-solid fa-user" style={{ marginRight: '4px' }}></i>
                Solo or up to 5 players
              </span>
            </div>
            <div className="text-center buttons">
              <a href="https://store.steampowered.com/app/1644910/Nightmare_Party/" target="_blank" rel="noreferrer" className="button"><i className="fa fa-brands fa-steam"></i>Wishlist on Steam</a>
              <a href="https://www.youtube.com/watch?v=yG42hk2Szps" target="_blank" rel="noreferrer" className="button"><i className="fa fa-brands fa-youtube"></i>Watch trailer</a>
              <a href="https://discord.gg/HnsHDzFpUs" target="_blank" rel="noreferrer" className="button"><i className="fa fa-brands fa-discord"></i>Join Discord</a>
              {/*<a href="#faq" className="button"><i className="fa fa-solid fa-question"></i>FAQ</a>*/}
            </div>
            {/*<div className="video-container">*/}
            {/*  <div className='embed-container'>*/}
            {/*    <iframe title="Video" src='https://www.youtube.com/embed/yG42hk2Szps' frameBorder="0" scrolling="0" allowFullScreen="1" />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>

      <div className="overlay grunge-border"></div>

      <div className="section-bg second-page h-f v-f bg-f" style={{ padding: '0' }}>
        <div className="center-container">
          <div className="content">
            <h2 className="text-center mb-none" style={{ textShadow: '1px 1px 3px #000' }}>About the game</h2>
            <p style={{ textShadow: '1px 1px 3px #000' }}>
              <span className="first-letter">Y</span>ou are seasoned demon hunters out on a regular job but something went sideways. You are trapped by an entity stronger than you've ever seen.
              Your goal is simple: survive and escape. This might sound easy, but dealing with evil entities is a dangerous business!
            </p>
            <table>

            </table>
          </div>
        </div>
      </div>

      <div className="overlay grunge-border"></div>

      <div className="section-bg light pt-xl pb-xl text-center">
        <div className="center-container">
          <h2 className="text-center mb-l">
            <span className="small">Are you tired of being powerless?</span>
            <span className="big">Fight back</span>
          </h2>
          <p className="mb-l">
            In most horror games you run and hide, you are powerless. Nightmare Party is about fighting back, one of the most important mechanics of the game is the gunplay.
          </p>
          <h2 className="text-center mt-xl">
            <span className="small">Be prepared and</span>
            <span className="big">Choose your loadout & skills</span>
          </h2>
          <p className="mb-l">
            Are you going in guns blazing? Or do you like a more stealth approach? Select your weapons and use suppressors.
            Choose your passive and active skills but be aware that all of them has a cost.
          </p>
          <h2 className="text-center mt-xl">
            <span className="small">Pay attention</span>
            <span className="big">You are never safe</span>
          </h2>
          <p className="mb-l">
            Danger is lurking at every corner. This game is not for the faint-hearted.
          </p>
          <h2 className="text-center mt-xl">
            <span className="small">Manage your</span>
            <span className="big">Madness</span>
          </h2>
          <p className="mb-l">
            As your madness increases you become disconnected from reality. Keep it as low as you can or face the consequences!
          </p>
        </div>
      </div>

      <div className="overlay grunge-border"></div>

      <div className="section-bg footer">
        <div className="center-container text-center">
          <img className="mad-raven-games-logo" src="mad-raven-games-wide-logo-grey.png" alt="Mad Raven Games logo"/>
          <p>
            Copyright Mad Raven Games.<br />
            All rights reserved. <br />
            <a href="mailto:contact@madravengames.com">contact@madravengames.com</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Main